import React from 'react'

import { CmsContentPage } from '@grandvisionhq/bloomreach'
import { NextPage } from '@grandvisionhq/next'

import { layoutSettings, withCms } from '../../cms'

const ContentPage: NextPage = () => {
  return <CmsContentPage {...layoutSettings} />
}

export default withCms({ wrapInContainerPage: false })(ContentPage)
